import React, { useState, useEffect } from 'react';
import { PageHeader, Tabs } from 'antd';
import { Card, Table, Button, message } from 'antd';
import { GetLeaveRequestDto, UpdateLeaveRequestDto } from '../../../services/model/LeaveRequestModel';
import { leaveRequestService } from '../../../services/others/leave-request-services';
import SweetAlertService from '../../../services/lib/utils/sweet-alert-service';

const LeaveRequestApprovePage: React.FC = () => {
  const [dataSource, setDataSource] = React.useState<GetLeaveRequestDto[]>([]);

  useEffect(() => {
    document.title = "Leave Request Approve";
    fetchRequests();
  }, []);

  const fetchRequests = async () => {
    const response = await leaveRequestService.getAll();
    setDataSource(response.data.data);
  };

  const pendingDataSource = dataSource.filter(item => item.approvalStatus === 'Pending Approval');
  const rejectedDataSource = dataSource.filter(item => item.approvalStatus === 'Rejected');
  const approvedDataSource = dataSource.filter(item => item.approvalStatus === 'Approved');

  const handleApprove = async (record: GetLeaveRequestDto) => {
    const result = await SweetAlertService.inputConfirm({
      type: 'text',
      title: 'Reason',
      defaultValue: '',
      placeholder: 'Reason for approval',
    });
    if (!result) return;
    try {
      await leaveRequestService.approve(record.leaveRequestId, result);
      message.success('Leave request approved');
      fetchRequests();
    } catch (error) {
      message.error('Failed to approve leave request');
    }
  };

  const handleReject = async (record: GetLeaveRequestDto) => {
    const result = await SweetAlertService.inputConfirm({
      type: 'text',
      title: 'Reason',
      defaultValue: '',
      placeholder: 'Reason for rejection',
    });
    if (!result) return;

    try {
      await leaveRequestService.reject(record.leaveRequestId, result);
      message.success('Leave request rejected');
      fetchRequests();
    } catch (error) {
      message.error('Failed to reject leave request');
    }
  };

  const columns = [
    {
      title: 'Employee',
      dataIndex: ['employee', 'firstName'],
      key: 'employeeName',
      render: (text: string, record: GetLeaveRequestDto) => (
        <span>
          <i className="fas fa-user" style={{ marginRight: 8 }}></i>
          {`${record.employee?.firstName} ${record.employee?.lastName}`}
        </span>
      ),
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
      render: (date: Date | null) => date ? new Date(date).toLocaleDateString('en-GB') : 'N/A',
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
      render: (date: Date | null) => date ? new Date(date).toLocaleDateString('en-GB') : 'N/A',
    },
    {
      title: 'Leave Type',
      dataIndex: ['leaveType', 'typeName'],
      key: 'leaveType',
    },
    {
      title: 'Reason',
      dataIndex: 'leaveReason',
      key: 'leaveReason',
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date: Date | null) => date ? new Date(date).toLocaleDateString('en-GB'): 'N/A',
    },
    {
      title: 'Approval Status',
      dataIndex: 'approvalStatus',
      key: 'approvalStatus',
    },
    {
      title: 'Approval Comment',
      dataIndex: 'approvalComment',
      key: 'approvalComment',
    },
    {
      title: 'Approved At',
      dataIndex: 'approvedAt',
      key: 'approvedAt',
      render: (date: Date | null) => date ? new Date(date).toLocaleDateString('en-GB') : 'N/A',
    },    
    {
      title: 'Actions',
      key: 'actions',
      render: (text: string, record: GetLeaveRequestDto) => (
        <>
          {record.approvalStatus === 'Pending Approval' && (
            <>
              <Button type="primary" onClick={() => handleApprove(record)}>
                Approve
              </Button>
              <Button danger style={{ marginLeft: 8 }} onClick={() => handleReject(record)}>
                Reject
              </Button>
            </>
          )}
        </>
      ),
    },
  ];

  return (
    <PageHeader
      title="Leave Requests"
      style={{ backgroundColor: '#f0f2f5', padding: '16px' }}
      avatar={{ icon: <i className="fas fa-calendar-check" style={{ color: '#1890ff' }} /> }}
    >
      <Card style={{ borderRadius: '8px', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)' }}>
        <Tabs defaultActiveKey="pending" type="card" animated>
          <Tabs.TabPane tab="Pending" key="pending">
            <Table
              dataSource={pendingDataSource}
              columns={columns}
              rowKey="id"
              pagination={{ pageSize: 50 }}
              style={{ marginTop: '16px' }}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Rejected" key="rejected">
            <Table
              dataSource={rejectedDataSource}
              columns={columns}
              rowKey="id"
              pagination={{ pageSize: 50 }}
              style={{ marginTop: '16px' }}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Approved" key="approved">
            <Table
              dataSource={approvedDataSource}
              columns={columns}
              rowKey="id"
              pagination={{ pageSize: 50 }}
              style={{ marginTop: '16px' }}
            />
          </Tabs.TabPane>
        </Tabs>
      </Card>
    </PageHeader>
  );
}
export default LeaveRequestApprovePage;

