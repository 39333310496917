import { TaskResponse } from '../../common/model';
import { GetLeaveRequestDto, AddLeaveRequestDto, UpdateLeaveRequestDto } from '../model/LeaveRequestModel';
import { ApiRequest } from '../api/api';

export const leaveRequestService = {
  async approve(id: string, comment: string): Promise<TaskResponse<any>> {
    return ApiRequest({
      url: `/LeaveRequest/approve/${id}`,
      method: 'put',
      data: { comment: comment }
    });
  },

  async reject(id: string, comment: string): Promise<TaskResponse<any>> {
    return ApiRequest({
      url: `/LeaveRequest/reject/${id}`,
      method: 'put',
      data: { comment: comment }
    });
  },

  async delete(id: string): Promise<TaskResponse<any>> {
    return ApiRequest({
      url: `/LeaveRequest/${id}`,
      method: 'delete'
    });
  },
  async getAll(): Promise<TaskResponse<any>> {
    return ApiRequest({
      url: '/LeaveRequest',
      method: 'get'
    });
  },

  async getById(id: string): Promise<TaskResponse<any>> {
    return ApiRequest({
      url: `/LeaveRequest/${id}`,
      method: 'get'
    });
  },
  async getByEmployeeId(id: number): Promise<TaskResponse<any>> {
    return ApiRequest({
      url: `/LeaveRequest/byEmployee/${id}`,
      method: 'get'
    });
  },
  async add(request: AddLeaveRequestDto): Promise<TaskResponse<any>> {
    return ApiRequest({
      url: '/LeaveRequest',
      method: 'post',
      data: request
    });
  },

  async update(request: UpdateLeaveRequestDto): Promise<TaskResponse<any>> {
    return ApiRequest({
      url: '/LeaveRequest',
      method: 'put',
      data: request
    });
  },


  async getLeaveTypes(): Promise<TaskResponse<any>> {
    return ApiRequest({
      url: '/WeeklyReport/GetAllLeaveTypes',
      method: 'get'
    });
  }
};

